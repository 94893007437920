import { React, useEffect } from 'react'
import firebase from 'firebase'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { useAuthState } from 'react-firebase-hooks/auth'
import MainScreen from './components/mainScreen'

const appName = 'BayesScale'

/* Firebase Stuff */
const firebaseConfig = {
  apiKey: 'AIzaSyDAnZszRB9e8b-JSm_4-MXK75cU7wK399I',
  authDomain: 'bayesscale.firebaseapp.com',
  databaseURL: 'https://bayesscale.firebaseio.com',
  projectId: 'bayesscale',
  storageBucket: 'bayesscale.appspot.com',
  messagingSenderId: '83732305871',
  appId: '1:83732305871:web:7ffbe7b8f790f4f71664ba',
}
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to / after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
}

const auth = firebase.auth()
const database = firebase.database()
/* End of Firebase stuff */

function App() {
  useEffect(() => {
    document.title = appName
  }, [])

  const [user] = useAuthState(auth) // user is null when the user is signed out
  return (
    <div>
      {user ? (
        <MainScreen auth={auth} database={database} user={user} />
      ) : (
        <SignInScreen />
      )}
    </div>
  )
}

function SignInScreen() {
  return (
    <div className='flex flex-col h-screen bg-white'>
      <div className='m-auto text-center md:p-32'>
        <p className='text-8xl'>🥝</p>
        <h1 className='text-6xl font-bold py-5'>{appName}</h1>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
    </div>
  )
}

export default App
