import React, { useState, useEffect } from 'react'
import { GoalForm, RecordWeightForm } from './forms'
import { getDaysToGoalDate } from '../logic'
import { InfoText, NavBar, Footer } from './components'
import { TimeSeries, Index } from 'pondjs'
import {
  Resizable,
  ChartContainer,
  ChartRow,
  Charts,
  LineChart,
  YAxis,
  styler,
} from 'react-timeseries-charts'
import moment from 'moment'

let weightArray = []
let lookback = 30

function InfoSection({ userData, forecastData }) {
  return (
    <div className='mx-4 py-6'>
      <h1 className='header pb-4'>Forecast</h1>

      {'prob_of_goal' in forecastData ? (
        <div>
          <InfoText
            subhead='Probability of reaching your goal'
            numberString={`${(forecastData['prob_of_goal'] * 100).toFixed(0)}%`}
          />
          <InfoText
            subhead='Change of weight per day'
            numberString={`${forecastData['mean_weight_change'].toFixed(4)}`}
          />
          <InfoText
            subhead='Estimated calorie deficit'
            numberString={`${(
              forecastData['mean_weight_change'] * 3600
            ).toFixed(0)}`}
          />
          <InfoText
            subhead='Day until goal date'
            numberString={getDaysToGoalDate(userData['goalDateString'])}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

function InputSection({ database, userData, userID }) {
  return (
    <div className='divide-y mx-4'>
      <RecordWeightForm userID={userID} database={database} />
      <br />

      <GoalForm userID={userID} database={database} userData={userData} />
      <br />
    </div>
  )
}

export default function MainScreen({ user, auth, database }) {
  const [isUserDataReady, setIsUserDataReady] = useState(false)
  const [isForecastDataReady, setIsForecastDataReady] = useState(false)
  const [isGraphReady, setIsGraphReady] = useState(false)
  const [weightSeries, setWeightSeries] = useState(null)
  const [userData, setUserData] = useState({})
  const [forecastData, setForecastData] = useState({
    prob_of_gain: 0.0,
    prob_of_loss: 0.0,
    prob_of_goal: 0.0,
    mean_weight_change: 0.0,
  })

  // Graph variables
  const style = styler([
    {
      key: 'value',
      color: '#77ab43',
      width: 5,
    },
  ])

  // Hooks tutorial https://www.youtube.com/watch?v=TNhaISOUy6Q
  useEffect(
    () => {
      database
        .ref(`users/${user.uid}`)
        .once('value')
        .then((userDataSnapshot) => {
          setUserData(userDataSnapshot.val())
          setIsUserDataReady(true)

          database
            .ref(`weights/${user.uid}`)
            .orderByKey()
            .limitToLast(lookback)
            .on('value', (snapshot) => {
              let plotData = []
              weightArray = []
              for (const key in snapshot.val()) {
                const weight_i = snapshot.val()[key]['weight']
                const timestamp_i = moment(
                  snapshot.val()[key]['timestamp'],
                  'DD-MM-YYYY'
                ).toDate()

                plotData.push([
                  Index.getDailyIndexString(timestamp_i),
                  weight_i,
                ])
                weightArray.push(weight_i)
              }

              plotData = {
                name: 'weight',
                columns: ['index', 'value'],
                points: plotData,
              }
              setWeightSeries(new TimeSeries(plotData))
              setIsGraphReady(true)

              // Get forecast
              const lastWeight = weightArray[weightArray.length - 1]
              const forecastRequestBody = JSON.stringify({
                is_weight_loss:
                  userDataSnapshot.val()['goalWeight'] < lastWeight,
                days_future: getDaysToGoalDate(
                  userDataSnapshot.val()['goalDateString']
                ),
                goal_weight: userDataSnapshot.val()['goalWeight'],
                weights: weightArray,
                lookback: lookback,
              })

              // Testing python backend 'http://127.0.0.1:5000/forecast'
              fetch('https://us-central1-gspython-154503.cloudfunctions.net/bayesscale-forecast', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: forecastRequestBody,
              })
                .then((response) => response.json())
                .then((data) => {
                  setForecastData(data)
                  setIsForecastDataReady(true)
                })
            })
        })
    },
    [] // Empty so it should run only once
  )

  return (
    <div>
      <NavBar auth={auth} />

      {isUserDataReady ? (
        <div>
          {/* Graph Section */}
          <div className='px-4 md:px-16 pt-8'>
            {isGraphReady ? (
              <div className='max-w-screen-lg m-auto'>
                <Resizable>
                  <ChartContainer timeRange={weightSeries.range()}>
                    <ChartRow height='300'>
                      <YAxis
                        id='weight'
                        label='Weight'
                        min={weightSeries.min()}
                        max={weightSeries.max()}
                        width={50}
                        format='.1f'
                      />
                      <Charts>
                        <LineChart
                          axis='weight'
                          series={weightSeries}
                          style={style}
                        />
                      </Charts>
                    </ChartRow>
                  </ChartContainer>
                </Resizable>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          {/* Information & Input Section */}
          <div className='md:flex justify-evenly block py-5'>
            <InfoSection userData={userData} forecastData={forecastData} />
            <InputSection
              userData={userData}
              userID={user.uid}
              database={database}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <Footer />
    </div>
  )
}
