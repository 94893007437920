import React from 'react'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import { getToday, dateToUnixtime, dateToTimestamp } from '../logic'


function RecordWeightForm({ userID, database }) {
  return (
    <Formik
      initialValues={{
        weight: '',
      }}
      validate={(values) => {
        const errors = {}
        if (typeof values.weight == 'number') {
          if (0 >= values.weight || values.weight > 1000) {
            errors.weight = 'Invalid weight'
          }
        }
        return errors
      }}
      onSubmit={({ weight }) => {
        let today = getToday(new Date())
        database.ref(`weights/${userID}/${dateToUnixtime(today)}`).set({
          unixtime: dateToUnixtime(today),
          timestamp: dateToTimestamp(today),
          weight: parseFloat(weight)
        })
      }}
    >
      <Form>
        <div className='grid grid-cols-1 gap-6 py-6'>
          <h1 className='header'>Record Weight</h1>

          <label>
            <span>Weight</span>
            <ErrorMessage
              className='text-red-600'
              name='weight'
              component='div'
            />
            <Field
              className='text-input'
              id='weight'
              name='weight'
              type='number'
            />
          </label>

          <button className='btn bg-green-700 hover:bg-green-500' type='submit'>
            Save
          </button>
        </div>
      </Form>
    </Formik>
  )
}

function GoalForm({ userData, database, userID }) {
  return (
    <Formik
      initialValues={{
        goalWeight: userData['goalWeight'],
        goalDate: userData['goalDateString'],
      }}
      validate={(values) => {
        const errors = {}
        if (typeof values.goalWeight == 'number') {
          if (50 >= values.goalWeight || values.weight < 1000) {
            errors.goalWeight = 'Invalid weight'
          }
        }
        return errors
      }}
      onSubmit={({goalWeight, goalDate}) => {
        database.ref(`users/${userID}`).set({
          goalDateString: goalDate,
          goalWeight: goalWeight
        })
      }}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 gap-6 py-6'>
            <h1 className='header'>Goal</h1>

            <label className='block'>
              <span>Goal Weight</span>
              <Field
                className='text-input'
                name='goalWeight'
                id='goalWeight'
                type='number'
              />
            </label>

            <label className='block'>
              <span>Goal Date</span>
              <Field
                className='text-input'
                name='goalDate'
                id='goalDate'
                type='date'
              />
            </label>

            <button
              className='btn bg-gray-700 hover:bg-gray-500'
              disabled={isSubmitting}
              type='submit'
            >
              Update
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export { RecordWeightForm, GoalForm }
