import React from 'react'


function InfoText(props) {
  const subhead = props.subhead
  const numberString = props.numberString

  return (
    <div>
      <h3 className='text-2xl text-gray-600 font-semibold'>{subhead}</h3>
      <h4 className='text-5xl text-gray-700'>{numberString}</h4>
      <br />
    </div>
  )
}

function NavBar(props) {
  return (
    <nav className='flex justify-between items-center h-16 bg-white relative shadow-md'>
      <div className='flex p-8'>
        <h1 className='text-2xl pr-2 md:block hidden'>🥝</h1>
        <h1 className='font-black text-2xl'>BayesScale</h1>
      </div>

      <div className='p-8'>
        <button
          onClick={() => props.auth.signOut()}
          className='py-2 px-5 text-white bg-green-500 hover:bg-green-300 transition duration-300 font-bold rounded-full'
        >
          Sign Out
        </button>
      </div>
    </nav>
  )
}

function Footer() {
  return (
    <div className='flex justify-center h-16 items-center bg-gray-800 text-white'>
      <p>Made with 🤍 by Stephen</p>
    </div>
  )
}

export { InfoText, NavBar, Footer }
