function range(start, stop, step) {
  if (typeof stop == 'undefined') {
    // one param defined
    stop = start
    start = 0
  }

  if (typeof step == 'undefined') {
    step = 1
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return []
  }

  let result = []
  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i)
  }

  return result
}

function sortOnKeys(dict) {
  let sorted = []
  for (let key in dict) {
    sorted[sorted.length] = key
  }
  sorted.sort()
  sorted.reverse()

  let tempDict = {}
  for (let i = 0; i < sorted.length; i++) {
    tempDict[sorted[i]] = dict[sorted[i]]
  }

  return tempDict
}

function getDateString(unixtimes) {
  let dates = []
  unixtimes.forEach(function (unixtime) {
    let date = new Date(unixtime)
    dates.push(
      `${date.getFullYear()}-${('0' + date.getMonth()).slice(-2)}-${(
        '0' + date.getDate()
      ).slice(-2)}`
    )
  })
  return dates
}

function getToday() {
  let today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)
  today.setMilliseconds(0)
  return today
}

function daysBetween(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((second - first) / (1000 * 60 * 60 * 24))
}

function dateToUnixtime(date) {
  return Math.floor(date.getTime() / 1000)
}

function dateToTimestamp(date) {
  return `${('0' + date.getDate()).slice(-2)}-${(
    '0' +
    (date.getMonth() + 1)
  ).slice(-2)}-${date.getFullYear()}`
}

function getDaysToGoalDate(goalDateString) {
  const parsedDate = parseInt(Date.parse(goalDateString))
  return daysBetween(getToday(), parsedDate)
}

export {
  getDaysToGoalDate,
  daysBetween,
  getToday,
  dateToUnixtime,
  dateToTimestamp,
}
